import React, { useState, useEffect } from 'react';
import { 
  Avatar, 
  Button,
  TextField, 
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Link, useHistory,useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { toast } from 'react-toastify';
import AuthorizationClient from '../single-page/api/AuthorizationClient'
import FileField from '../components/FileField.js'
import LoadingButton from '../components/LoadingButton.js'
import { MainContainer } from './config/PublicThemeProvider';
import { BlueButton } from './config/PublicThemeProvider';
import sgpLogo from './images/sgp-logo.png';
import Switch from '@material-ui/core/Switch';
import {GoogleLogin} from 'react-google-login';

const rdmcolor = '#D12E5E'

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: rdmcolor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    avatar: '',
    google_token: ''
  });


  const [stateSwitch, setStateSwitch] = useState(false);
  
  function handleChangeSwitch(event){
    setStateSwitch(!stateSwitch);
  };

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const location = useLocation();
  let planType = '';
  let planInternalId = '';
  let redirect = '';
  
  
  function sign_up(e) {
    if (e) {
      e.preventDefault();
    }
    document.activeElement.blur();
    setLoading(true);
    toast.dismiss();


    // SIGN UP NÃO VEM HABILITADO POR PADRÃO NO TEMPLATE!
    
    AuthorizationClient.signUp(user)
      .then(function(){ 
        if (typeof location.state !== "undefined") {
          planType = `&type=${location.state.type}`;
          planInternalId = `&internal_id=${location.state.internal_id}`;
        } else {
          redirect = '&redirect=true'
        }
        if (!user.google_token) {
          window.location.href = `/app/dashboard?flash_notice=Olhe sua caixa de e-mail para concluir o processo de cadastro${planType}${redirect}${planInternalId}`;
        } else {
          window.location.href = `/app/dashboard?flash_notice=Cadastro efetuado com sucesso${planType}${redirect}${planInternalId}`;
        }
      })
      .catch(function(response){
        
        // console.log('Error: ', response);

        if (response.status == 422 && response.data.errors){
          setErrors(response.data.errors);
        } else {
          setErrors({});
        }
        if (response.data && response.data.error) {
          toast.warning(`${response.data.error}`);
        } else {
          toast.error(`Erro ao processar solicitação.`);
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  useEffect(() => {
    if ( user.email && user.name && user.google_token ) {
      // console.log("email e token preenchidos");
      sign_up();
    }
  },[user.email, user.name, user.google_token])

  function responseGoogle(response){

    if (!response.profileObj) return null;

    setUser({...user, email: response.profileObj.email, name: response.profileObj.name, google_token: response.tokenId})
  }  

  function checkUser(){
    return !loading && stateSwitch && userPresentField('name') &&  userPresentField('email') && userPresentField('password') ;
  }
  function userPresentField(field){
    return user[field].length > 0
  }  

  return (
    <MainContainer style={{maxWidth: '550px'}}>
      <div className={classes.paper}>
        <a href="/">  <img src={sgpLogo} style={{ width: 185, margin: '0.5rem' }} /> </a>
        <Typography component="h1" variant="h5">
          Criar minha conta
        </Typography>
        <form className={classes.form} onSubmit={sign_up} noValidate>
          {[
            { name: 'name', label: 'Nome', type: 'text', autoFocus: true, required: true },
            { name: 'email', label: 'Email', type: 'email', required: true },
            { name: 'password', label: 'Senha', type: 'password', required: true },
          ].map((field) =>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required={field.required}
              key={field.name}
              id={field.name}
              name={field.name}
              label={field.label}
              fullWidth={true}
              autoComplete={field.name}
              autoFocus={field.autoFocus}
              value={user[field.name]}
              error={errors[field.name] && true}
              type={field.type}
              helperText={errors[field.name] ? errors[field.name].join(', ') : ''}
              onChange={(e) => setUser({...user, [field.name]: e.target.value})}
            />
          )}
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item xs={2}>
              <Switch
                checked={stateSwitch}
                onChange={handleChangeSwitch}
                name="switchTermos"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography component="h1" variant="subtitle1">
                Aceito os <a href="https://web.sgpar.app/AdhesionContract" target="_blank" >Termos e Condições</a> de utilização do SGP+AR
              </Typography>  
            </Grid>          
          </Grid>

          <BlueButton
            type="submit"
            fullWidth
            // loading={loading}
            variant="contained"
            color="primary"
            disabled={!checkUser()}
            className={classes.submit}
          >
            {loading ? 'Aguarde...' : 'Criar minha conta'}
          </BlueButton>       
        </form> 
        <span style={{marginBottom: '1em'}} > ou </span>
        <GoogleLogin
          disabled={!stateSwitch}
          clientId={props.googleClientId || "690935168634-pa609j3lptkh115vm8cfuusplifojdgq.apps.googleusercontent.com"}
          buttonText="Cadastre-se com Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
           
         
        <Grid container  style={{marginTop: 16}}>
          <Grid item xs>
            <a href="/login" >
                  Entrar
              </a>
          </Grid>
          <Grid item>
            <a href="https://web.sgpar.app/SignIn/ForgotPassword" target="_blank">
                Esqueceu sua senha?
            </a>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
}