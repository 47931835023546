import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    height: 239, 
    textAlign: 'center', 
    padding: '1.875rem', 
    margin: '0 auto', 
    marginBottom: '1.875rem', 
    border: '1px solid rgba(0, 0, 0, 0.07)',
    transition: '0.3s',
    boxSizing: 'content-box',
    maxWidth: 298,
    transition: '.3s',
    '&:hover': {
      backgroundColor: '#1baec5',
      color: 'white',
    },
    '&:hover .cardTitle': {
      color: 'white',
    },   
    '&:hover .cardDescription': {
      color: 'white',
    },    
    '&:hover .iconClass': {
      color: 'white',
    },            
    '@media(max-width: 368px)': {
      height: 'unset',
    },
  },
  cardTitle: {
    textDecoration: 'none',
    color:  '#1baec5',    
    fontSize: '1.5rem',
    // fontSize: 24,
    display: 'block',
    marginBottom: '1.25rem',
    transition: 'all .3s',
    lineHeight: 1,
  },
  cardDescription: {
    lineHeight: 1.7,
    // fontSize: 16,
    fontSize: '1rem',
    cursor: 'default',
    transition: 'all .3s',
  },
  iconClass: {
    color: '#1baec5',
  }
}));

export default function CardSettings({ text, title, iconClass }) {
  const classes = useStyles();

  
  return (
    <div className={classes.card} >
      <div style={{ width: 70, height: 70, margin: '0 auto', marginBottom: 20 }}>
        <i className={`${iconClass} ${classes.iconClass} iconClass`} style={{ lineHeight: 1 }}></i>
      </div>
      <a  className={`${classes.cardTitle} cardTitle`}>{title}</a>
      <p className={`${classes.cardDescription} cardDescription`}>{text}</p>
    </div>
  );
}
