import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
   '& .MuiAccordionSummary-content': {
     justifyContent: 'center',
   }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const useStyles = makeStyles({
  planItem: {
    margin: 0,
    padding: '15px 0',
    paddingRight: '15px',
    paddingLeft: '15px',
   
    transition: 'all 0.3s ease-in-out 0s',
    color: '#a7a7a7',
    // fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#E4E4E4',
      borderLeft: '5px solid #21adc4',
    },
  },
  planTitle: {
    fontSize: '1.75rem',
    margin: 0,
    paddingTop: '2.5rem',
    marginBottom: '3.125rem',
    paddingRight: '20px',
    paddingLeft: '20px',
    position: 'relative',
    zIndex: 1,
    fontWeight: '400',
    transition: 'all 0.3s ease-in-out 0s',
    color: props => props.hover ? 'white' : '#525252',
    '@media(max-width: 321px)': {
      paddingTop: '2rem',
    },
    '@media(max-width: 1024px)': {
      fontSize: '1.4rem',
    },
  },
  rectCrop: {
    position: 'absolute',
    backgroundColor: props => props.hover ? '#21adc4' : '#e4e4e4',
    top: -20,
    left: -20,
    right: -20,
    height: 140,
    paddingBottom: 10,
    transition: 'all 0.3s ease-in-out 0s',
    transform: 'rotate(-3.5deg)',
    zIndex: 0,
    '@media(max-width: 321px)': {
      height: 100,
    },
  },
  btn: {
    marginRight: '14px',
    marginLeft: '14px',
    backgroundColor: props => props.hover ? '#21adc4' : 'white',
    color: props => props.hover ? 'white' : '#21adc4',
    transition: 'all 0.3s ease-in-out 0s',
  },
  titleContainer: {
    marginTop: '40px',minHeight: '340px' , maxHeight: '345px',
    '@media(max-width: 768px)': {
      minHeight: 234,
    },
  },
  itemsContainer: {
    position: 'relative', minHeight: '875px',
    '@media(max-width: 425px)': {
      minHeight: 720,
    },
  },
});

export default function PlanCard({ ButtonComponent, planItems, title, price, subtitle,type, linesWithBold, accordionItems }) {
  const [hover, setHover] = useState(false);
  const classes = useStyles({ hover });
  const classesAccordion = useAccordionStyles();


  function hoverOn() {
    setHover(true);
  }

  function hoverOff() {
    setHover(false);
  }
  
  return (
    <div onMouseEnter={hoverOn} onMouseLeave={hoverOff} style={{ textAlign: 'left', backgroundColor: 'white', position: 'relative' }}>
      <Grid container spacing={0} style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom:'15px'}}>
        <Grid item xs={12} className={classes.titleContainer}>
          <p style={{letterSpacing:'8px', fontSize:'14px', margin: 0}}>PLANO</p>
          <h1 style={{ color:'#3A3A3A', margin: 0, paddingBottom: '10px'}} >{title}</h1>
          <p style={{ margin: 0, letterSpacing: 0}}> {subtitle}  </p>
        </Grid> 
      </Grid>
           
      {/* <div style={{  position: 'relative', overflow: 'hidden' }}>           
        <div className={classes.rectCrop} /> */}
        
        {/* <div style={{ paddingLeft: 30, paddingRight: 30 }}>
          <span style={{ fontSize: '28px', fontWeight: '400', letterSpacing: '2px', margin: 0, padding: 0, textTransform: 'uppercase'}}>{price}</span>
        </div> */}
  
        {/* <h4 className={classes.planTitle}>{price}</h4>
      </div>
      <div style={{ padding: '15px 0 32px' }}>
        <ButtonComponent className={classes.btn} style={{ fontSize: '1.125rem', fontWeight: '400' }} />
      </div> */}

      <div className={classes.itemsContainer}>

        {planItems.map((plan,i) => (
          linesWithBold.find(element => element===i) == i
            ?
            <p className={classes.planItem} key={i} style={{fontSize: '15px'}}><span style={{ color: '#414141', fontWeight: 'bold'}}> {plan} </span></p>	
            :
            <p className={classes.planItem} key={i} style={{fontSize: '15px' }}><span style={{ color: '#414141' }}> {plan} </span></p>	         						
        ))}       
      </div>
      {accordionItems.length>0 &&
        <Accordion className={classesAccordion.root}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            
          >
            <Typography className={classesAccordion.heading} style={{color: '#21adc4', fontWeight: 'bold'}}>saiba mais</Typography>
          </AccordionSummary>
          
            {accordionItems.map((item, i) => (
              <AccordionDetails style={{padding: 0}}  key={i}>
                <p className={classes.planItem} key={i}><span style={{ color: '#414141' }}> {item} </span></p>	
              </AccordionDetails>
            ))}

          
        </Accordion>
      }  



    </div>
  );
}
