import React, {useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MainContainer, BlueButton } from '../config/PublicThemeProvider';
import { Link as LinkScroll } from 'react-scroll';
import { Link as Link} from 'react-router-dom'; 
import sgpLogo from '../images/logo-sgp-ar.png';
import sgpLogoOld from '../images/sgp-logo-home.png';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';



const useStyles = makeStyles((theme) => ({

  navBarWrap: {
    padding: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
      

      '& ul': {
        display: 'none',
      },
    },
  },
  menuIcon: {
    
    backgroundColor: 'rgb(33, 173, 196)',
    color: '#ffffff',
  },
  menuItemDrop: {
    padding: '8px 30px',
    fontSize: 13,
    fontWeight: '500',
    margin: '0 auto',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      fontSize: '.81rem',
      display: 'initial',
      fontWeight: 'bold',
    },
    '& li a': {
      fontSize: '.81rem',
      display: 'initial',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    '& li:nth-child(1)': {
      padding: '30px 15px',
    },
    '& li:nth-child(2)': {
      padding: '30px 15px',
    },
  },
  root: {
    display: 'flex',
    '@media(min-width: 768px)': {
      display: 'none',
    },
    '& > div': {


      width: '280px',
      height: '100vh !important',
      bottom: '0px !important',
      transform: 'unset !important',
      right: 0,
      left: 'unset !important',
      top: '90px !important',
      backgroundColor: 'rgb(33, 173, 196)',

     
    },
    '& .MuiIconButton-root':{
      borderRadius: '20%',
      backgroundColor: 'rgb(33, 173, 196)',
      color: '#ffffff',

    },
    '& .MuiPaper-root': {
      backgroundColor: 'rgb(33, 173, 196)',
      color: '#ffffff',
      padding: 10,
      boxShadow: 'none',
    },
    menuItem: {
      fontSize: '1rem',
      fontWeight: '500',
    },
    '& ul': {
      display: 'initial',
     
    },
    '& li':{
      justifyContent: 'flex-end',

      '&:hover': {
        backgroundColor: '#ffffff',
        color: 'rgb(33, 173, 196)',
      }
    },    
    '& a, & a:link, & a:visited': {
      color: '#ffffff',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: 'rgb(33, 173, 196)',
      textDecoration: 'none',
    }
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])  
  return (
    <MainContainer style={{ position: 'absolute', left: 0, right: 0, zIndex: 1 }}>
      <div className={classes.navBarWrap} id="topo" >
        {/* <img src={sgpLogo} style={{ width: 155 }} /> */}
        <img src={props.whiteLogo ? sgpLogo : sgpLogoOld} style={{ width: 155 }} />
        <ul className={classes.list}>
          <li><a href="#" style={{ color: '#21adc4' }}>HOME</a></li>
          <li>
            <LinkScroll 
              to="plans" 
              duration={1500}  
              smooth="easeInQuad" 
              style={{ color: '#3c3c3cb3' , cursor: 'pointer' }}
            >
              PLANOS
            </LinkScroll>
          </li>
          <li style={{padding: 15}}>
          {!props.currentUser ? 
            <Link to="/registro">
            <BlueButton style={{height: 42}}>Teste gratuitamente</BlueButton>
            </Link > 
            : ''
          }
          </li>
          <li>
            {props.currentUser && props.currentUser.operator ? 
              <a href="/app/dashboard">
                <BlueButton style={{height: 42}}>Acessar painel</BlueButton>
              </a> : ''
            }
            {props.currentUser && !props.currentUser.operator ? 
              <a href="/adm">
                <BlueButton style={{height: 42}}>Acessar painel</BlueButton>
              </a> : ''
            }        
            {!props.currentUser ? 
              <Link to="/login">
                <BlueButton style={{height: 42}}>Login</BlueButton>
              </Link > 
              : ''
            }  
          </li>
        </ul>
        <div className={classes.root}>
          <IconButton
            
            aria-label="open drawer"
            edge="end"
            onClick={handleToggle}    
            ref={anchorRef}      >
            <MenuIcon />
          </IconButton>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper style={{}}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}  className={classes.menuItem} >HOME </MenuItem>                        
                    <MenuItem onClick={handleClose}  className={classes.menuItem} ><LinkScroll activeClass="activemobile" to="plans"  smooth={true} spy={true}  offset={-70} duration={1000} >PLANOS</LinkScroll></MenuItem>
                    {!props.currentUser ? 
                    <MenuItem onClick={handleClose}  className={classes.menuItem} >
                      <a  href="/registro" >
                            TESTE GRÁTIS
                          </a > 
                    </MenuItem>
                           
                          : ''
                        }                          
                        {props.currentUser && props.currentUser.operator ? 
                          <MenuItem onClick={handleClose}  className={classes.menuItem} >
                            <a  href="/app/dashboard">ACESSAR PAINEL</a> 
                          </MenuItem>
                          : ''
                        }
                        {props.currentUser && !props.currentUser.operator ?
                        <MenuItem onClick={handleClose}  className={classes.menuItem} >
                          <a  href="/adm">ACESSAR PAINEL</a> 
                        </MenuItem> 
                         : ''
                        }        
                        {!props.currentUser ? 
                        <MenuItem onClick={handleClose}  className={classes.menuItem} >
                          <a  href="/login">LOGIN</a>
                        </MenuItem>
                           : ''
                        }       

                      <MenuItem onClick={handleClose}  className={classes.menuItem} style={{fontSize: 12}} >FECHAR X</MenuItem>

                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>        

      </div>
    </MainContainer>
  );
}
