import React, { useState, useEffect } from 'react';
import { 
  Avatar, 
  Button,
  TextField, 
  FormControlLabel,
  Checkbox, 
  Typography,
  Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AuthorizationClient from '../single-page/api/AuthorizationClient.js'
import { toast } from 'react-toastify'
import LoadingButton from '../components/LoadingButton.js'
import { MainContainer } from './config/PublicThemeProvider';
import { BlueButton } from './config/PublicThemeProvider';
import sgpLogo from './images/sgp-logo.png';
import {GoogleLogin} from 'react-google-login';






const rdmcolor = '#D12E5E'

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: rdmcolor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [googleToken, setGoogleToken] = useState('');
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  

  function signIn(e) {
    if (e) {
      e.preventDefault();
    }
    document.activeElement.blur();
    toast.dismiss();
    setLoading(true);
    AuthorizationClient.signIn({email: email, password: password, google_token: googleToken})
      .then(function(){
        window.location.href = '/app/dashboard?flash_notice=Você será redirecionado ao site do SGPAR!&redirect=true';
      })
      .catch(function(error){
        // console.log('Error: ', error.response);
        if (error.response.status == 401){
          if (error.response.data.error == 'Senha errada') {
            toast.warning('Senha inválida');
            //setPasswordError('Senha inválida');
          } else {
            toast.warning('Email não confirmado');
            //setEmailError('Email não confirmado');
          }


        } else {
          if (error.response.status == 404) {
            toast.warning('Usuário não encontrado');
          } else {
            toast.error('Erro ao processar solicitação.');

          }
          setEmailError();
          // Tratar algum outro erro
        }
      })
      .then(function() {
        setLoading(false);
      });
  }


  useEffect(() => {
    if (googleToken && email) {
      signIn();
    }
  },[googleToken, email])

  function responseGoogle(response){
    if (!response.profileObj) return null;

    setGoogleToken(response.tokenId)
    setEmail(response.profileObj.email)

  }  

  return (
    <MainContainer style={{maxWidth: '550px'}}>
      <div className={classes.paper}>
        <a href="/">  <img src={sgpLogo} style={{ width: 185, margin: '0.5rem' }} /> </a>
        <Typography component="h1" variant="h5">
          Entrar
        </Typography>
        <form className={classes.form} onSubmit={signIn}  noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            value={email}
            error={emailError}
            helperText={emailError}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            error={passwordError}
            helperText={passwordError}
            onChange={(e) => setPassword(e.target.value)}
            label="Senha"
            type="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembre-se de mim"
          />

          <BlueButton
            disabled={loading}
            type="submit"
            fullWidth
           // loading={loading}
            variant="contained"
            color="primary"
            
            className={classes.submit}
          >
            {loading ? 'Aguarde...' : 'Entrar'}
          </BlueButton>
        </form>
        <span style={{marginBottom: '1em'}} > ou </span>
        <GoogleLogin
          clientId={props.googleClientId || "690935168634-pa609j3lptkh115vm8cfuusplifojdgq.apps.googleusercontent.com"}
          buttonText="Login com Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          // render={renderProps => (
          //   <Button
          //     onClick={renderProps.onClick} 
          //     variant="contained"
          //     color="default"
          //     style={{height: '48px', backgroundColor: 'white' }}
          //     startIcon={<img style={{width: '30px'}} src={googleSVG} />}
          //   >
          //    Login com Google
          //   </Button>           
          // )}
        />  

     
             
        <Grid container           style={{marginTop: 16}}>
          <Grid item xs >
            <a href="https://web.sgpar.app/SignIn/ForgotPassword" target="_blank">
                Esqueceu sua senha?
            </a>
          </Grid>
          <Grid item xs  >
             <a href="/registro" >
                Não possui uma conta? Cadastre-se
            </a>            
     
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
}