import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import PublicThemeProvider from './config/PublicThemeProvider';
import Routes from './config/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';

// NOTE: Production
if (process.env.NODE_ENV != 'development') {
  Sentry.init({ dsn: 'https://70b429f6e56b40e287767daf79391de5@sentry.roadmaps.com.br/38' });
}

export default function Public(props) {
  const [whiteLogo, setWhiteLogo] = useState(true);
  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (props.flash.notice) {
      toast.info(props.flash.notice);
    }
    if (props.flash.notice) {
      toast.warning(props.flash.alert);
    }
  }, []);

  return (
    <div id="main-div">
      <ToastContainer autoClose={4000} />
      <PublicThemeProvider>
        <Routes googleClientId={props.google_client_id} currentUser={props.currentUser} whiteLogo={whiteLogo} setWhiteLogo={setWhiteLogo} />
       
      </PublicThemeProvider>
    </div>
  );
}