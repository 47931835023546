import React, { useEffect, useState,  } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BlueButton } from '../config/PublicThemeProvider';
import banner1 from '../images/ilustracao-banner-1.png';
import banner2 from '../images/ilustracao-banner-2.png';
import { Link as LinkScroll } from 'react-scroll';
import {Grid} from '@material-ui/core';
// import '../styles/carousel-path.css';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


const useStyles = makeStyles((theme) => ({
  carouselItem: {
   
  },
  root: {
    width:'100%', fontWeight: 'bold',display: 'flex', justifyContent: 'center',alignItems: 'center',
    backgroundColor: '#fff',
    minHeight: 870,
    '@media(min-width: 766px)': {
      height: '100vh', 
    },
  },
  textSide:{
    padding: 20,

    '@media(min-width: 376px)': {
      marginTop: '4rem',
      padding: '25px 0px 0px 0px',

    },    

    '@media(max-width: 375px)': {
      marginTop: '4rem',
      padding: '25px 0px 0px 14px',
    },    

    
  },
  imageSide: {
    maxWidth: 390, 
    padding: 20,
    marginTop: '-3rem',
    '@media(max-width: 767px)': {
      marginTop: 0,
      padding: 20,
      // contentVisibility: 'hidden',
    },
    
  },
  imageSide2: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 390, 
    padding: 20,
    marginTop: '5rem',
    '& div ': {
      width: 210,
    },
    '@media(max-width: 767px)': {
      marginTop: 0,
      padding: 20,
      textAlignLast: 'center',
    },
    
  },

  
}));
export default function CarouselContainer(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  function handleChange(index) {
    // setCurrentIndex(index);
  }

  // function currentIndexIs(index) {
  //   return currentIndex === index;
  // }

  useEffect(()=> {
    setTimeout(() => {
      props.setWhiteLogo(true);
      // if (currentIndex == 1) {
      //   props.setWhiteLogo(true);
      // } else {
      //   props.setWhiteLogo(false);
      // }
    })
  },[currentIndex]);



  return (
    <div style={{backgroundColor: '#fff'}}>
      <Carousel 
        animationSpeed={2500}
        infinite
        autoPlay={10000}
        dots
        
      >
            <Grid container spacing={0} className={classes.root} > 
                <Grid item xs={12} sm={6} style={{maxWidth: 390,  padding: 10}}> 
                    <div className={classes.carouselItem}>
                      <div className="animate__animated animate__fadeIn animate__fast" className={classes.textSide}>
                        <h1 style={{ fontSize: 30, margin: 0, marginBottom: '1.25rem', lineHeight: 1.2, color: '#21adc4' }}>Encante seu cliente com mais tecnologia e menos papel. Use Realidade Aumentada para maximizar a percepção da qualidade de seus projetos.</h1>
                        <p style={{ color: '#C8C8C8', fontSize: '0.875rem', lineHeight: 1.5, marginBottom: '1.25rem' }}>Colabore, gerencie, visualize, interaja e mantenha o controle sobre seus projetos utilizando a Realidade Aumentada. Mostre ao seu cliente toda a qualidade do seu trabalho de um jeito que ele nunca viu.</p>
                        <BlueButton style={{ fontSize: '1.125rem' }}>
                          <LinkScroll 
                            to="plans" 
                            duration={1500}  
                            smooth="easeInQuad" 
                            style={{ color: '#ffffffb3', cursor: 'pointer' }}
                          >
                            Comece agora!
                          </LinkScroll>                  
                        </BlueButton>
                      </div> 
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.imageSide}>
                  <div  >
                    <img src={banner1} style={{width: '100%'}} />
                  </div>                  
                </Grid>              
            </Grid>
            <Grid container spacing={0} className={classes.root} > 
                <Grid item xs={12} sm={6} style={{maxWidth: 390,  padding: 10}}> 
            
                    <div className={classes.carouselItem}>
                      <div className="animate__animated animate__fadeIn animate__fast" className={classes.textSide}>
                        <h1 style={{ fontSize: 30, margin: 0, marginBottom: '1.25rem', lineHeight: 1.2, color: '#21adc4' }}>Tudo o que você sempre sonhou para a gestão de seus projetos visualizando em RA e muito mais!</h1>
                        <p style={{ color: '#C8C8C8', fontSize: '0.875rem', lineHeight: 1.5, marginBottom: '1.25rem' }}>Gerencie, visualize, interaja e mantenha o controle sobre os ativos dos seus projetos, utilizando a Realidade Aumentada de uma forma que você nunca imaginou, mas sempre desejou.</p>
                        <BlueButton style={{ fontSize: '1.125rem' }}>
                          <LinkScroll 
                            to="plans" 
                            duration={1500}  
                            smooth="easeInQuad" 
                            style={{ color: '#ffffffb3', cursor: 'pointer' }}
                          >
                            Comece agora!
                          </LinkScroll>                  
                        </BlueButton>
                      </div> 
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.imageSide2}>
                  <div  >
                    <img src={banner2} style={{width: '100%'}} />
                  </div>                  
                </Grid>              
            </Grid>

      </Carousel>      
    </div>
  );
}
