import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Index from '../pages/Index';
import SignIn from '../SignIn';
import SignUp from '../SignUp';

import NavBar from '../components/NavBar';
// import { useGlobalContext } from '../context/globalContext';

export default function Routes(props) {
  // const { loginDialogOpen } = useGlobalContext();
  

  return (
    <Router>

      <Route exact path={["/site", "/"]}>
        <NavBar currentUser={props.currentUser} whiteLogo={props.whiteLogo} />
        <ScrollToTop />      
        <Index setWhiteLogo={props.setWhiteLogo} />
      </Route>
      <Route exact path="/login">
        <SignIn googleClientId={props.googleClientId} />
      </Route>  
      <Route exact path="/registro">
        <SignUp googleClientId={props.googleClientId} />
      </Route>         
       
    </Router>
  );
}