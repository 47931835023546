import React from 'react';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { CssBaseline, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 16,
        },
        body: {
          fontSize: 'unset',
          fontFamily: "Work Sans, Open Sans, Sans Serif",
          backgroundColor: 'white',
          lineHeight: 1.7,
          color: '#828282',
        },
        input: {
          fontFamily: 'inherit',
        },
        h2: {
          fontSize: '1.875rem',
          lineHeight: 1.2,
        },
        '@media(max-width: 1280px)': {
          html: {
            fontSize: 16,
          },
        },
        '@media(max-width: 960px)': {
          html: {
            fontSize: 15,
          },
        },
        '@media(max-width: 768px)': {
          html: {
            fontSize: 15,
          },
        },
        '@media(max-width: 425px)': {
          html: {
            fontSize: 15,
          },
        },
        '@media(max-width: 368px)': {
          html: {
            fontSize: 14,
          },
        },
        '@media(max-width: 321px)': {
          html: {
            fontSize: 12,
          },
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'inherit',
        fontWeight: 'bold',
        borderRadius: 5,
      },
      label: {
        textTransform: 'none',
      },
    },
    MuiContainer: {
      root: {
        padding: '0px 15px !important',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 425,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      orange: '#F29047',
      blue: '#21adc4',
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
    },
  },
});

export default function PublicThemeProvider({ children }){
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export const MainContainer = ({ children, style = {} }) => {
  return (
    <Container component="div" style={{ maxWidth: 1170, ...style }}>
      {children}
    </Container>
  );
}

export const BlueButton = withStyles({
  root: {
    backgroundColor: theme.palette.common.blue,
    fontSize: '1rem',
    padding: '0.5rem 1.25rem',
    color: 'white',
    borderRadius: 30,
    fontFamily: 'inherit',
    fontWeight: '400',
    border: '2px solid #21adc4',
    '&:hover': {
      backgroundColor: '#55c9dc',
      border: '2px solid #55c9dc',
    },
  }
})(Button);

export const OutlinedBlueButton = withStyles({
  root: {
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.common.blue}`,
    padding: '0.5rem 1.25rem',
    boxSizing: 'unset',
    borderRadius: 30,
    color: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue,
      color: 'white',
    },
  },
})(Button);
