import React, { Fragment, useState, useEffect } from 'react';
import { 
  Grid,  
  
} from '@material-ui/core';
import { MainContainer, BlueButton, OutlinedBlueButton } from '../config/PublicThemeProvider';
import { Link } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import TalkToUsContainer from '../components/TalkToUsContainer';
import CarouselContainer from '../components/CarouselContainer';
import PlanCard from '../components/PlanCard';
import CardSettings from '../components/CardSettings';
import handsImg from '../images/hands-img.jpg';
import colaboradorImg from '../images/colaborador-img.jpg';
import bannerComece from '../images/banner-comece.jpg';
import facebookIcon from '../images/facebook.svg';
import linkedinIcon from '../images/linkedin.svg';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import upArrow from '../images/up-arrow.png'
import '../styles/flaticon.css';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import qrCodeImg from '../images/qr_code.png';
import googleImg from '../images/google_play.png';






const useStyles = makeStyles({
  scrollToTopLink: {
    backgroundColor: '#00000080',
    width: 50,
    height: 50,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '.3s',
    opacity: props => props.scrollTopLink ? 1 : 0,
    '& img': {
      width: 15,
    },
  },
  bloco1: {
    maxWidth: 916, margin: '0 auto', textAlign: 'center', padding: '6.15rem 0px',
    '@media(max-width: 768px)': {
      padding: '1rem 0px'    
    },    

  },
  bloco2: {
    padding: '6.15rem 0px',
    '@media(max-width: 768px)': {
      padding: '1rem 0px'    
    }, 
  },
  bloco3: {
    margin: '6.15rem 0px',
    '@media(max-width: 768px)': {
      margin: '1rem 0px'    
    }, 
  },
  bloco4: {
    margin: '4rem 0px',
    '@media(max-width: 768px)': {
      margin: '1rem 0px'    
    }, 
  },
  footer:{
    backgroundColor: '#747474', padding: '7rem 0 4rem 0' ,
    '@media(max-width: 768px)': {
      margin: '1rem 0px'    
    }, 
  }
  
});






export default function Index(props) {
  let history = useHistory(); 
  const [selectedConsultPlan, setSelectedConsultPlan] = useState('');

  const [scrollTopLink, setScrollTopLink] = useState(false);
  const classes = useStyles({ scrollTopLink });

  const [indexSelectedPlan, setIndexSelectedPlan] = useState({
    type: ''      
  });

  function scroll(e) {
    setScrollTopLink(e.currentTarget.scrollY > 200);
  }


  function handleClickPlan(plan){
    if (plan == "free") {
      setIndexSelectedPlan({ ...indexSelectedPlan, type: 'free' });
    } else {
      setIndexSelectedPlan({ ...indexSelectedPlan, type: 'prepaid' });
    }

  }

  useEffect(() => {
    window.addEventListener('scroll', scroll);
    setScrollTopLink(window.scrollY > 200);

    return () => window.removeEventListener('scroll', scroll);
  }, []);

  return (
    <Fragment>
      <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1 }}>
        <Link disabled to="main-div" duration={1500} smooth="easeInQuad" className={classes.scrollToTopLink}>
          <img src={upArrow} />
        </Link>
      </div>
      <div  >
        <CarouselContainer setWhiteLogo={props.setWhiteLogo} />
      </div>
      <div style={{ backgroundColor: '#21adc4' }}>
        <MainContainer>
          <div className={classes.bloco1}>
            <h2 style={{ fontSize: '1.875rem', lineHeight: 1.5, color: 'white' }}>Finalmente um software que vai mudar sua maneira de visualizar, apresentar e gerir todos os seus projetos!</h2>
          </div>
        </MainContainer>
      </div>
      <div style={{ backgroundColor: '#F5F5F5' }}>
        <MainContainer>
          <div className={classes.bloco2}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <h2 style={{ margin: 0, marginBottom: 20, color: '#1baec5' }}>Tudo o que você sempre sonhou<br />para a gestão de seus projetos<br />visualizando em RA e muito mais!</h2>
                <p style={{ color: '#3D3D3D' }}>Tudo em um só lugar, em qualquer fase<br />do projeto e à vista. Uma experiência única<br />e totalmente inovadora, que vai evoluir<br />a maneira de gerir o seu negócio e elevar<br />o patamar da sua produtividade.</p>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                {/* <img src={playerYoutube} style={{ width: '100%' }} /> */}
                <ReactPlayer
                  // url={ ENV['VIDEO_INDEX'] || 'https://sgp-ar-site.s3.amazonaws.com/Vi%CC%81deo+curto.mp4' }
                  url={'https://sgp-ar-site.s3.amazonaws.com/Vi%CC%81deo+curto.mp4' }
                  width="100%"
                  playing
                  autoPlay 
                  muted
                  controls
                  loop
              />                
              </Grid>
            </Grid>
          </div>
        </MainContainer>
      </div>

      <div className={classes.bloco3}>
        <MainContainer>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={5}>
              <img src={handsImg} style={{ width: '100%', borderRadius: 6, marginBottom: '1rem' }} />
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h2 style={{ color: '#1baec5', margin: 0, marginBottom: '1.25rem' }}>Tudo visual, com realidade aumentada, compatível com seu software de trabalho preferido e instantâneo!</h2>
              <p style={{ color: '#3D3D3D' }}>• Elabore ou importe dos principais software do mercado um cronograma completo para registrar o progresso físico da obra para medições instantâneas direto do projeto.<br />• Crie seu próprio checklist e faça inspeções de qualquer área do projeto de forma online ou offline.<br />• Vincule todo o controle ao modelo BIM ou a um item do seu cronograma, controlando todos os itens de ação através de um calendário, lista ou Kanban.</p>
            </Grid>
          </Grid>
        </MainContainer>
      </div>
      <div>
        <img src={colaboradorImg} style={{ width: '100%' }} />
      </div>

      <MainContainer>
        <div className={classes.bloco4}>
          <div style={{ marginBottom: '5rem', textAlign: 'center' }}>
            <h2 style={{ margin: 0, marginBottom: '1.25rem', color: '#1baec5' }}>Ferramenta elaborada para você ter<br />controle total do seu projeto!</h2>
            <p style={{ maxWidth: 750, margin: '0 auto', marginBottom: '1.25rem', color: '#828282', fontSize: '1.125rem' }}>As áreas de trabalho se comunicam, transformando o ciclo de trabalho em um processo dinâmico desde a primeira ideia do projeto até a manutenção do seu ativo no pós obra.</p>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CardSettings
                title="BIM"
                text="As áreas de trabalho se comunicam sem ruídos, transformando o ciclo de trabalho em um projeto dinâmico."
                iconClass="flaticon-eye"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardSettings
                title="Empreendimento"
                text="Criar, editar, deletar e gerenciar projetos, além de acompanhar suas atividades (itens de ação)."
                iconClass="flaticon-house-building"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardSettings
                title="Memórias da reunião"
                text="Registre tópicos e assuntos tratados nas reuniões, anexe documentos, decisões, etc."
                iconClass="flaticon-contract"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardSettings
                title="Stakeholders"
                text="Adicione colaboradores que fazem parte do projeto (proprietário, clientes e colaboradores)."
                iconClass="flaticon-people"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardSettings
                title="Itens de ação"
                text="Crie tarefas, adicione um responsável, uma data com prazo de entrega e um status personalizados."
                iconClass="flaticon-notebook"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardSettings
                title="Checklist"
                text="Organizando toda sua gestão de qualidade por disciplina."
                iconClass="flaticon-check-list"
              />
            </Grid>
          </Grid>
        </div>
      </MainContainer>
      <div style={{ lineHeight: 0 }}>
        <img src={bannerComece} style={{ width: '100%' }}/>
      </div>
      <div id="plans" style={{ backgroundColor: '#EFEDED', padding: '2.5rem 0px' }}>
        <MainContainer >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <div style={{padding: 1}} >
                <PlanCard
                  title="Grátis"
                  subtitle="Visualize seu projeto no melhor app de Realidade Aumentada do mercado, em qualquer escala, e no seu navegador de forma simples e rápida."
                  price="GRÁTIS" 
                  type="free"
                  linesWithBold ={[]}
                  planItems = {[
                    '1 projeto',
                    'Open BIM (IFC 2X3 ou 4.0)',
                    'Realidade Aumentada de uma disciplina com nosso app',
                    'BIM 3D no navegador (Chrome, Edge, Firefox)',
                    'Filtros BIM por famílias e pavimentos em Realidade Aumentada',
                    'Memórias de reunião e encaminhamentos',
                    'Quadro de gerenciamento de tarefas com visualizações em estilo "Kanban", tabela ou calendário',
                    'GED para repositório & organização dos arquivos do projeto',
                    'Limite de upload de 15MB para IFC',
                    'Limite total de 1GB',
                  ]}
                  accordionItems={[

                  ]}
                  ButtonComponent={(props) => {
                    return <OutlinedBlueButton onClick={() => history.push({pathname: '/registro', state: {type: 'free'}})}  {...props}>TESTE GRÁTIS!</OutlinedBlueButton>;
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div style={{padding: 1}} >
                <PlanCard
                  title="Básico"
                  subtitle="Visualize e colabore em até 5 projetos no melhor app de Realidade Aumentada do mercado, em qualquer escala, no seu navegador de forma simples e rápida"
                  price="R$ 350,00/MÊS" 
                  decimal="00" 
                  type="prepaid"
                  linesWithBold ={[0]}

                  planItems = {[
                    'Tudo do Plano Grátis +',
                    'Até 5 projetos no total',
                    'Até 6 disciplinas BIM por projeto',
                    'Realidade Aumentada com calibração em escala real',
                    'Colaboração e acesso às funcionalidades para até 10 usuários e até 5 clientes',
                    'Colaboração via Chat com usuários e clientes',
                    'Sobreposição de até 3 disciplinas em Realidade Aumentada no nosso app',
                    'Gravação de mídia direto do aplicativo em campo e visualização na plataforma',
                    'Criação de itens de ação a partir da análise das mídias existentes',
                    'Tratamento de não conformidades'
                  ]}
                  accordionItems={[
                    'Limite de upload de 30MB para IFC',
                    'Limite total de  5GB'
                  ]}
                  ButtonComponent={(props) => {
                    return <OutlinedBlueButton onClick={() => history.push({pathname: '/registro', state: {type: 'prepaid', internal_id: 2}})} {...props}>ASSINAR AGORA</OutlinedBlueButton>;
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div style={{padding: 1}} >
                <PlanCard
                  title="Profissional"
                  subtitle="Visualize e colabore em até 20 projetos no melhor app de Realidade Aumentada do mercado, em qualquer escala, com cronograma e controle de qualidade no app e no navegador de forma simples e rápida"
                  price="R$ 750,00/MÊS" 
                  type="prepaid"
                  linesWithBold ={[0]}
                  textBeforeItems = {[]}
                  textAfterItem = {[]}
                  planItems = {[
                    'Tudo do Plano Básico +',
                    'Até 20 projetos no total',
                    'Até 20 disciplinas BIM por projeto',
                    'Colaboração e acesso às funcionalidades para até 50 usuários e até 20 clientes',
                    'Criação de modelos e preenchimento de checklist em campo pelo app de forma online/offline ',
                    'Checklists vinculados ao modelo BIM e em Realidade Aumentada',
                    'Criação ou importação de cronograma na própria plataforma',
                    'Plugin para o Autodesk Revit ®',
                    'Plugin para Microsoft Project ®',
                    'Realidade Aumentada online e offline'
                  ]}
                  accordionItems={[
                    'Limite de upload de 100MB para IFC',
                    'Limite total de  10GB'
                  ]}                
                  ButtonComponent={(props) => {
                    return <OutlinedBlueButton onClick={() => history.push({pathname: '/registro', state: {type: 'prepaid', internal_id: 3}})} {...props}>ASSINAR AGORA</OutlinedBlueButton>;

                  }}
                />
              </div>

                          
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <div style={{padding: 1}} >
                <PlanCard
                  title="Empresarial"
                  subtitle="Visualize e colabore em todos os seus projetos usando o melhor app de Realidade Aumentada do mercado e de acordo com as necessidades da sua empresa"
                  price="sob consulta" 
                  type="prepaid"
                  textBeforeItems = {[]}
                  textAfterItem = {[]}
                  linesWithBold ={[0]}
                  
                  planItems = {[
                    'Tudo do Plano Profissional +',
                    'Compartilhe as funcionalidades com todos os seus colaboradores & clientes',
                    'Em todos os seus projetos',
                    'Com todas as suas disciplinas BIM',
                    'Criação & configuração de empreendimentos',
                    'Criação de Sprints para o cronograma (Agile/Lean Construction)',
                    'Planejamento BIM4D vinculados ou não as Sprints',
                    'Checklists vinculados às atividades do cronograma',
                    'Checklists vinculados a elementos BIM e em Realidade Aumentada',
                    'Checklists com validação por fiscal ou terceirizado'

                  ]} 
                  accordionItems={[
                    'Medição de avanço físico utilizando BIM e Realidade Aumentada',
                    'Atualização automática do cronograma a partir das medições',
                    'Treinamento & suporte',
                    'Possibilidade de Integração com outros sistemas',
                    'API para leitura de dados do sistema para montagem de BI',
                    'Disponibilização de módulo BIM e Realiade Aumentada para ser integrado com outros sistemas',
                    'Sem limite de upload para IFC',
                    'Sem limite de GB por projeto'
                  ]}                    
                  ButtonComponent={(props) => {
                    return <OutlinedBlueButton  {...props}>            
                    <Link 
                      to="talk" 
                      duration={500}  
                      smooth="easeInQuad" 
                      onClick={() => setSelectedConsultPlan('Profissional')}
                      style={{cursor: 'pointer' }}
                    >
                      CONSULTAR
                    </Link>
                  </OutlinedBlueButton>;
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </MainContainer>
      </div>
      <div style={{ padding: '4.26rem 0' }} id="talk" >
        <MainContainer>
          <h2 style={{ margin: 0, marginBottom: '5rem', textAlign: 'center' }}>Fale conosco</h2>
          <TalkToUsContainer selectedConsultPlan={selectedConsultPlan} />
        </MainContainer>
      </div>
      <div className={classes.footer}>
        <MainContainer>
          <Grid container space={2}>
            <Grid item xs={12} sm={6}>
              <h4 style={{ color: 'white', fontSize: '0.875rem', textTransform: 'uppercase', paddingBottom: '0.9375rem' }}>A SGP+AR</h4>
              <p style={{ color: '#ffffff80' }}>Transforme a produtividade da sua empresa</p>
              <div style={{display: 'flex'}}>
                <a href="https://play.google.com/store/apps/details?id=com.simplifique.sgpplusar" target="_blank" >
                  <img src={qrCodeImg} style={{ width: 100}}/>
                  <img src={googleImg} style={{ width: 180}}/>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 style={{ color: 'white', fontSize: '0.875rem', textTransform: 'uppercase', paddingBottom: '0.9375rem' }}>Saiba mais</h4>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li><a href="#" style={{ color: '#ffffffcc', textDecoration: 'none' }}>Home</a></li>
                <li><a href="#" style={{ color: '#ffffffcc', textDecoration: 'none' }}>Apresentação</a></li>
                <li><a href="#" style={{ color: '#ffffffcc', textDecoration: 'none' }}>A Ferramenta</a></li>
                <li><a href="#" style={{ color: '#ffffffcc', textDecoration: 'none' }}>Planos</a></li>
                <li><a href="#" style={{ color: '#ffffffcc', textDecoration: 'none' }}>Fale conosco</a></li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginBottom: '2.5rem'}}>
                <p style={{ textAlign: 'center', color: '#ffffff80' }}>Copyright &copy;2020 All rights reserved | SGP+AR</p>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <a href="https://www.instagram.com/sgp.ar/" target="_blank" style={{ width: 40 }}>
                  <InstagramIcon style={{fontSize: 40 , width: '100%', filter: 'invert(60%) sepia(100%) saturate(386%) hue-rotate(140deg) brightness(81%) contrast(88%)' }}/>
                </a>
                <a href="https://www.facebook.com/sgpplus.ca/" target="_blank" style={{ width: 40 }}>
                  <img src={facebookIcon} style={{ width: '100%', filter: 'invert(60%) sepia(100%) saturate(386%) hue-rotate(140deg) brightness(81%) contrast(88%)' }}/>
                </a>
                <a href="https://www.linkedin.com/in/sgp-plus-3144541a3/" target="_blank"  style={{ width: 40 }}>
                  <img src={linkedinIcon} style={{ width: '100%', filter: 'invert(60%) sepia(100%) saturate(386%) hue-rotate(140deg) brightness(81%) contrast(88%)' }}/>
                </a>
                <a href="https://youtube.com/channel/UCkGA4SkqnvOr8ThD1OUS6UA" target="_blank" style={{ width: 40 }} >
                  <YouTubeIcon style={{ fontSize: 40 , width: '100%', filter: 'invert(60%) sepia(100%) saturate(386%) hue-rotate(140deg) brightness(81%) contrast(88%)' }}/>
                 
                </a>
              </div>
            </Grid>
          </Grid>
        </MainContainer>
      </div>
    </Fragment>
  );
}
